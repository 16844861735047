
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { update, beforeUpdate, failedUpdate } from "../../redux/userSlice";
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import authImg from "../../assets/img/auth/auth.png";
import { Button } from "@mui/material";


export default function SignIn() {
  const user = useSelector((state) => state.user);

  const [authFields, setAuthFields] = useState({
    email: "",
    password: ""
  });
  
  const dispatch = useDispatch();
  const { email, password } = authFields;

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setAuthFields({ ...authFields, [name]: value });
  };

  const navigate = useNavigate();

  const SendCredentialsToRegisterAPI = async () => {
    try {
      dispatch(beforeUpdate());
      const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/login`, authFields)
      dispatch(update(resp?.data));
      navigate('/');
      toast.success('Welcome!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    } catch (err) {
      console.log(err)
      dispatch(failedUpdate(err?.response?.data?.error));
      toast.error(err?.response?.data?.error, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const isDisabled = email.trim() === "" || password.trim() === "";
  return (
    <>
      <div className="relative mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
        {/* Sign in section */}
        <img
          src={authImg}
          alt="logo"
          className="lg:left-[5rem] absolute top-0 w-[14rem] "
        />

        <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 ">
            Sign In
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600 ">
            Enter your email and password to sign in!
          </p>

          <div className="mb-3">
            <label htmlFor="email" className="block text-base text-gray-700 ">
              Email*
            </label>
            <input
              className="appearance-none border rounded-lg py-3 px-4 leading-tight focus:outline-none focus:shadow-outline focus:border-green-600 focus:ring-2 focus:ring-green-600 w-full"
              id="email"
              name="email"
              type="text"
              value={email}
              onChange={handleOnChange}
              placeholder="mail@simmmple.com"
            />
          </div>
          {/* Password */}
          <div className="mb-3">
            <label htmlFor="password" className="block text-base text-gray-700 ">
              Password*
            </label>
            <input
              className="appearance-none border rounded-lg py-3 px-4 leading-tight focus:outline-none focus:shadow-outline focus:border-green-600 focus:ring-2 focus:ring-green-600 w-full"
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={handleOnChange}
              placeholder="Enter your password"
            />
            <Link className="text-semibold text-base my-1 text-green-500 hover:underline" to="/forget-password">Forget Password ?</Link>
          </div>

          <Button

            onClick={SendCredentialsToRegisterAPI}
            size="large"
            sx={{ width: '100%' }} disabled={isDisabled || user.loading}
            variant="contained" color='success'>
            {user.loading ? (
              <CircularProgress color="primary" />

            ) : (
              <>Sign In</>
            )}

          </Button>

        </div>

      </div>
    </>
  );
}
