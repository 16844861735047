import { createSlice } from '@reduxjs/toolkit'


export const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: null,
    loading: false,
    error: null,
  },
  reducers: {
    // currently the update part is only be used by Login Module
    beforeUpdate: (state, action) => void (
      state.loading = true
    ),
    update: (state, action) => void (
      state.userData = action.payload,
      state.loading = false
    ),
    failedUpdate: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    remove: (state, action) => {
      state.userData = action.payload
    }
  },



})

export const { update, beforeUpdate, failedUpdate, remove } = userSlice.actions
export default userSlice.reducer;