import React from 'react'

const Settings = () => {
  return (
    <div>
        ss
    </div>
  )
}

export default Settings