import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const CreateJob = () => {
    const initialFormData = {
        jobTitle: '',
        location: '',
        experienceFrom: '1',
        experienceTo: '',
        description: '',
        jobType: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const token = user?.userData?.token;
    const navigate = useNavigate();

    const handleEditorChange = (state) => {
        setEditorState(state);
        const contentState = state.getCurrentContent();
        setFormData({ ...formData, description: draftToHtml(convertToRaw(contentState)) });
    };

    async function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(formData)
        };

        const apiEndpoint = `${process.env.REACT_APP_BASE_URL}/api/jobs/create-job`;

        try {
            const response = await fetch(apiEndpoint, requestOptions);
            const data = await response.json();

            if (response.status === 201) {
                toast.success(data.message, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
                navigate('/jobs');
                return;
            } else if (response.status === 400 || response.status === 401 || response.status === 403) {
                toast.error(data.error, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
                if (response.status === 403) {
                    navigate('/signin');
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className='flex justify-center'>
            <Container maxWidth='md'>
                <Typography variant='h1' align='center' gutterBottom>
                    Add new job Vacancy
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label='Job Title'
                        name='jobTitle'
                        value={formData.jobTitle}
                        onChange={handleChange}
                        margin='normal'
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        <TextField
                            fullWidth
                            label='Job Type'
                            name='jobType'
                            value={formData.jobType}
                            onChange={handleChange}
                            margin='normal'
                        />
                        <TextField
                            fullWidth
                            label='Location'
                            name='location'
                            value={formData.location}
                            onChange={handleChange}
                            margin='normal'
                        />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        <TextField
                            fullWidth
                            label='Experience From (in years)'
                            type='number'
                            name='experienceFrom'
                            value={formData.experienceFrom}
                            onChange={handleChange}
                            margin='normal'
                        />
                        <TextField
                            fullWidth
                            label='Experience To (in years)'
                            type='number'
                            name='experienceTo'
                            value={formData.experienceTo}
                            onChange={handleChange}
                            margin='normal'
                        />
                    </Box>

                    <Typography variant='h6' gutterBottom>
                        Description
                    </Typography>
                    <Box sx={{ border: '1px solid #ccc', padding: 2, minHeight: '300px' }}>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                        />
                    </Box>
                    <Button variant='contained' color='primary' type='submit' disabled={isLoading}>
                        Add Job Vacancy
                    </Button>
                </form>

            </Container>
        </div>
    );
};

export default CreateJob;
