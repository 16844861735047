import Footer from "../../components/footer/FooterAuthDefault";
import { Route } from "react-router-dom";
import FixedPlugin from "../../components/fixedPlugin/FixedPlugin";
import SignIn from "../../pages/Auth/SignIn";
import { Box } from "@mui/material";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  
  return (
    <Box>
      <div className={`relative float-right h-full min-h-screen w-full`}>
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col items-center pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                {/* <img
                    src={authImg}
                    alt="logo"
                    className="w-[12rem] "
                  /> */}
                <SignIn />
                <div className="absolute right-0 hidden h-full min-h-screen lg:block lg:w-[49vw] 2xl:w-[44vw]  md:hidden ">
                  <img
                    src="https://source.unsplash.com/random?wallpapers"
                    alt="logo"
                    className="h-full w-full"
                  />
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </Box>
  );
}
