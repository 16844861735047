import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { update } from "../../redux/userSlice";
import Loader from '../Loader';

// NOTE: this route is protected but verify only token.
const ProtectedRoute = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { userData } = useSelector((state) => state.user);
    const token = userData?.token;
    const dispatch = useDispatch();

    const verifyToken = async () => {
        try {
            setIsLoading(true)
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/auth/verify-token`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Cache-Control': 'no-store'
                    }
                }
            );
            const data = await response.json();
            if (response.ok) {
                dispatch(update(data));
                return;
            }
            dispatch(update(null));
        } catch (err) {
            console.log(err)
            dispatch(update(null));
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        verifyToken();
    }, [])

    return (
        isLoading ? <Loader />
            : (
                <>
                    <Outlet />
                </>
            )
    )
}

export default ProtectedRoute
