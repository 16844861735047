import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./pages/global/Topbar";
import Sidebar from "./pages/global/Sidebar";
import Dashboard from "./pages/dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "./layouts/auth/index";
import { useSelector } from "react-redux";
import CreateJob from "./pages/Jobs/create";
import { Jobs } from "./pages/Jobs/jobs";
import UpdateJob from "./pages/Jobs/update";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import Settings from "./pages/settings/Settings";
import ForgetPassword from "./pages/forget-password";
import ResetPassword from "./pages/reset-password";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { userData } = useSelector((state) => state.user);

  return (
    <>
      <ToastContainer />
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {window.location.pathname !== "/signin" &&
              userData &&
              isSidebar && <Sidebar isSidebar={isSidebar} />}

            <main className="content">
              {window.location.pathname !== "/signin" && userData && (
                <Topbar setIsSidebar={setIsSidebar} />
              )}
              <Routes>
              <Route path="/forget-password" element={<ForgetPassword />} />
                <Route path="/reset-password/:token" element={
                userData ? <Navigate to="/" replace /> : <ResetPassword />
                } />
                <Route
                  path="/signin"
                  element={
                    userData ? <Navigate to="/" replace /> : <AuthLayout />
                  }
                />
                <Route
                  element={
                    userData ? <ProtectedRoute /> : <Navigate to="/signin" />
                  }
                >
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/jobs" element={<Jobs />} />
                  <Route path="/jobs/create" element={<CreateJob />} />
                  <Route path="/jobs/update/:jobId" element={<UpdateJob />} />
                </Route>
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;
