import React from 'react'
import Stack from '@mui/material/Stack';
import { CircularProgress } from '@mui/material';
// import CircularProgress from '@mui/material/CircularProgress';
function Loader({ height }) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: `${height ? height : "80vh"}`,
            }}
        >
            <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                <CircularProgress sx={{ color: "#374767" }} />
            </Stack>
        </div>
    )
}

export default Loader