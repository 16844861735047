import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const UpdateJob = () => {
    const initialFormData = {
        jobTitle: '',
        location: '',
        experienceFrom: '1',
        experienceTo: '',
        description: '',
        jobType: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const token = user?.userData?.token;

    let { jobId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/jobs/get-job/${jobId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Cache-Control': 'no-store',
                        },
                    }
                );
                const data = await response.json();
                if (response.ok) {
                    const contentBlock = htmlToDraft(data.description);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setEditorState(editorState);

                    setFormData({
                        jobTitle: data.jobTitle,
                        location: data.location,
                        experienceFrom: data.experienceFrom,
                        experienceTo: data.experienceTo,
                        description: data.description,
                        jobType: data.jobType,
                    });
                } else {
                    toast.error(data.error, {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    });
                    if (response.status === 403) navigate('/signin');
                }
            } catch (error) {
                console.error(error);
            }
        };
        if (jobId) {
            fetchJob();
        }
    }, [jobId, token, navigate]);

    const handleEditorChange = (state) => {
        setEditorState(state);
        const contentState = state.getCurrentContent();
        setFormData({ ...formData, description: draftToHtml(convertToRaw(contentState)) });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        };

        const apiEndpoint = `${process.env.REACT_APP_BASE_URL}/api/jobs/update-job/${jobId}`;

        try {
            const response = await fetch(apiEndpoint, requestOptions);
            const data = await response.json();

            if (response.status === 200) {
                toast.success(data.message, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
                navigate('/jobs');
            } else {
                toast.error(data.error, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className='flex justify-center'>
            <Container maxWidth='md'>
                <Typography variant='h1' align='center' gutterBottom>
                    Update Job Vacancy
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label='Job Title'
                        name='jobTitle'
                        value={formData.jobTitle}
                        onChange={handleChange}
                        margin='normal'
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>

                        <TextField
                            fullWidth
                            label='Job Type'
                            name='jobType'
                            value={formData.jobType}
                            onChange={handleChange}
                            margin='normal'
                        />
                        <TextField
                            fullWidth
                            label='Location'
                            name='location'
                            value={formData.location}
                            onChange={handleChange}
                            margin='normal'
                        />

                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        <TextField
                            fullWidth
                            label='Experience From (in years)'
                            type='number'
                            name='experienceFrom'
                            value={formData.experienceFrom}
                            onChange={handleChange}
                            margin='normal'
                        />
                        <TextField
                            fullWidth
                            label='Experience To (in years)'
                            type='number'
                            name='experienceTo'
                            value={formData.experienceTo}
                            onChange={handleChange}
                            margin='normal'
                        />
                    </Box>
                    <Typography variant='h6' gutterBottom>
                        Description
                    </Typography>
                    <Box sx={{ border: '1px solid #ccc', padding: 2, minHeight: '300px' }}>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            wrapperClassName='demo-wrapper'
                            editorClassName='demo-editor'
                        />
                    </Box>
                    <Button variant='contained' color='primary' type='submit' disabled={isLoading}>
                        Update Job
                    </Button>
                </form>
            </Container>
        </div>
    );
};

export default UpdateJob;
