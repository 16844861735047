import React, { useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import axios from 'axios';


const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email) {
            toast.error("Please enter your email first");
            return;
        }

        try {
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/forget-password`, { email });
            toast.success(response.data.message);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'An error occurred';
            toast.error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="xs" style={{ marginTop: '20px' }}>
            <Typography color="green" variant="h4" gutterBottom>
                Forget Password
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    color='success'
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button disabled={isLoading} type="submit" variant="contained" color="success" fullWidth>
                    {isLoading ? (
                        <CircularProgress color="primary" />

                    ) : (
                        <>         
                         Send Reset Link
                        </>
                    )}
                </Button>
            </form>
        </Container>
    );
};

export default ForgetPassword;
