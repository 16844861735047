import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { ModalButton } from '../../components/ModalButton/ModalButton';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';

const columns = [
  { id: 'jobTitle', label: 'Job Title', minWidth: 170 },
  { id: 'jobType', label: 'Job Type', minWidth: 100 },
  { id: 'location', label: 'Location', minWidth: 100 },
  {
    id: 'experienceFrom',
    label: 'Experience From',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'experienceTo',
    label: 'Experience To',
    minWidth: 170,
    align: 'right',
  },
  // {
  //   id: 'description',
  //   label: 'Description',
  //   minWidth: 20,
  //   align: 'right',
  // },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 170,
    align: 'right',
  },
];

// Modal Styling
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const Jobs = () => {
  // ---------- Table States ------------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // -------------------------------------------
  const user = useSelector((state) => state.user);
  const token = user?.userData?.token;
  const [jobs, setJobs] = useState([])
  const [deleteId, setDeleteId] = useState("");
  const [editId, setEditId] = useState("")

  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/get-all`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Cache-Control': 'no-store'
            }
          }
        );
        const data = await response.json();


        setJobs(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUsers();
  }, [page]);


  // ----------------- Delete Modal ---------------------------
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = (deleteId) => {
    setDeleteId(deleteId);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setDeleteId("");
    setOpenDelete(false);
  };

  // ----------------- END Delete Modal ---------------------------


  // ----------------- Edit Modal ---------------------------
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = (editId) => {
    setEditId(editId);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEditId("");
  };

  // ----------------- END Edit Modal ---------------------------
  const deleteJob = async (jobId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/jobs/delete-job/${jobId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (response.ok) {
        setJobs(jobs.filter((job) => job._id !== jobId));
        setOpenDelete(false);
        toast.success('Job deleted successfully');
      } else if (response.status === 400) {
        toast.error(data.error, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (response.status === 401) {
        toast.error(data.error, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (response.status === 403) {
        toast.error(data.error, {
          position: "bottom-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate('/signin')
      }

    } catch (error) {
      console.error('Error deleting job:', error);
      toast.error('An error occurred while deleting job');
    }
  };

  const navigateToEditPage = (editId) => {
    navigate(`/jobs/update/${editId}`);
    setEditId("");
  }

  return (
    <>
      <div className="h-screen flex flex-col  items-center bg-white">
        <div className="container p-6  bg-white rounded-md">
          <div className="flex flex-col md:flex-row justify-between items-center w-full p-4">
            <h2 className="text-3xl font-bold mb-4 md:mb-0">Availble Job Vacancies</h2>
            <div className='flex gap-1'>
              <ModalButton
                message="Do you want to add new Job Vacancy ?"
                caption="Hey!"
                actionPerform={() => navigate('/jobs/create')}
                buttonStyle="btn btn-sm gap-2"
                btnColor="btn-success"
              >
                Add +
              </ModalButton>
            </div>
          </div>

          {/* Table */}
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.id === 'actions') {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <Button sx={{ mx: "2px" }} variant="contained" color="success" onClick={() => handleOpenEdit(row?._id)} >
                                    Edit
                                  </Button>
                                  <Button sx={{ mx: "2px" }} variant="outlined" color="error" onClick={() => handleOpenDelete(row?._id)}>Delete</Button>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : typeof value === "string" ? value.length <= 45 ? value : (value.substr(0, 18) + "...") : value
                                  }
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={jobs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>


        </div>


      </div>
      <ToastContainer />

      {/* //////////////////// Delete Modal /////////////////// */}
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            Hey!
          </Typography>
          <Typography id="modal-modal-description" variant="h5" sx={{ mt: 2 }}>
            Are you sure you want to Delete ?
          </Typography>

          <div className='mt-4 w-full flex justify-end gap-2'>
            <Button variant="contained" color='error' onClick={() => deleteJob(deleteId)}>
              Delete
            </Button>

            <Button variant="outlined" color='primary' onClick={handleCloseDelete}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
      {/* ///////////////////////////////////////////////////// */}


      {/* //////////////////// Edit Modal ///////////////////// */}
      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            Hey!
          </Typography>
          <Typography id="modal-modal-description" variant="h5" sx={{ mt: 2 }}>
            Are you sure you want to Edit ?
          </Typography>

          <div className='mt-4 w-full flex justify-end gap-2'>
            <Button variant="contained" color='success' onClick={() => navigateToEditPage(editId)}>
              Edit
            </Button>

            <Button variant="outlined" color='primary' onClick={handleCloseEdit}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
      {/* ///////////////////////////////////////////////////// */}
    </>
  )
}
