import { useState } from "react";

export const ModalButton = ({ children, buttonStyle, message, caption, actionPerform, actionStatus, btnColor }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const yesButtonClassName = btnColor ? `btn ${btnColor}` : "btn btn-error";

  return (
    <>
      <button disabled={actionStatus} className={`btn ${buttonStyle}`} onClick={openModal}>
        {children}
      </button>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50" onClick={closeModal}>
          <div className="fixed inset-0 bg-black opacity-50"></div>

          <dialog id="my_modal_1" className="modal" open>
            <form method="dialog" className="modal-box">
              <h3 className="font-bold text-2xl">{caption}</h3>
              <p className="py-4 text-xl">{message}</p>
              <div className="modal-action">
                <button className="btn" onClick={closeModal}>
                  No
                </button>

                <button className={yesButtonClassName} onClick={actionPerform}>
                  Yes
                </button>
              </div>
            </form>
          </dialog>
        </div>
      )}
    </>
  );
};

