import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Container,
    Typography,
    IconButton,
    InputAdornment,
    CircularProgress,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useParams();
    const navigate = useNavigate();

    const [redirectCountdown, setRedirectCountdown] = useState(null); // State for countdown timer


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!password || !confirmPassword) {
            toast.error('Please fill both fields');
            return;
        }

        if (password !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        try {
            setIsLoading(true);
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/auth/reset-password`,
                {
                    token,
                    password,
                    confirmPassword,
                }
            );
            toast.success(response.data.message);
            // Start the countdown for redirecting
            setRedirectCountdown(5); // Start with 5 seconds

            // Redirect after 5 seconds
            setTimeout(() => {
                navigate('/signin'); // Redirect to the login page
            }, 5000);
        } catch (error) {
            const errorMessage = error.response
                ? error.response.data.error
                : 'An error occurred';
            toast.error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="xs" style={{ marginTop: '50px' }}>
            <Typography color="green" variant="h4" gutterBottom>
                Reset Password
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    color="success"
                    label="New Password"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    color="success"
                    label="Confirm Password"
                    variant="outlined"
                    type={showConfirmPassword ? 'text' : 'password'}
                    fullWidth
                    margin="normal"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowConfirmPassword}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button type="submit" variant="contained" color="success" fullWidth>
                    {isLoading ? (
                        <CircularProgress color="primary" size={24} />
                    ) : (
                        'Reset Password'
                    )}
                </Button>
            </form>
            {redirectCountdown !== null && (
                <Typography color="primary" style={{ marginTop: '20px' }}>
                    Redirecting in {redirectCountdown} second{redirectCountdown !== 1 ? 's' : ''}
                </Typography>
            )}
            <ToastContainer />
            <ToastContainer />
        </Container>
    );
};

export default ResetPassword;
